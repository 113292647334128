
import { Component, Vue, Prop } from 'vue-property-decorator'
import { WorkInstructionItemDetail } from '@/types/insectMonitor'

@Component
export default class WorkInstructionDetail extends Vue {
  @Prop({ default: false }) private detailDialog!: boolean
   @Prop({ default: '' }) private workGuideId!: string

  private detailInfo: WorkInstructionItemDetail | null= null
  private loading = false
  created () {
    this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios
      .post(this.$apis.insectMonitor.addWorkGuideReadingCounts, {
        workGuideId: this.workGuideId
      })
      .then((res) => {
        if (res) {
          this.detailInfo = res
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  handleClose () {
    this.$emit('closeDialog')
  }
}
