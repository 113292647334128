
import { Component, Vue, Prop } from 'vue-property-decorator'
import { MedicamentItemDetail } from '@/types/insectMonitor'

@Component
export default class MedicamentDetail extends Vue {
  @Prop({ default: false }) private detailDialog!: boolean
  @Prop({ default: '' }) private medicineId!: string

  private detailInfo: MedicamentItemDetail | null = null
  private loading = false
  private carouselIndex = 0
   private watermark = '?imageView2/1/w/290/h/290/q/75|watermark/1/image/aHR0cDovL3FuLnBpYy5naW50ZWxsLmNuL2ppdXpoaV9sb2dvLnBuZw==/dissolve/100/gravity/SouthEast/dx/10/dy/10'
   created () {
     this.getDetail()
   }

   getDetail () {
     this.loading = true
     this.$axios
       .post(this.$apis.insectMonitor.addMedicineReadingCounts, {
         medicineId: this.medicineId
       })
       .then((res) => {
         console.log(res)
         if (res) {
           this.detailInfo = res
         }
       })
       .finally(() => {
         this.loading = false
       })
   }

   // 轮播改变
   carouselChange (index: number) {
     this.carouselIndex = index
   }

   handleClose () {
     this.$emit('closeDialog')
   }
}
