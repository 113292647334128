
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ClassVideoItemDetail } from '@/types/insectMonitor'

@Component
export default class ClassVideoDetail extends Vue {
  @Prop({ default: false }) private detailDialog!: boolean
  @Prop({ default: '' }) private videoId!: string

  private detailInfo: ClassVideoItemDetail | null = null
  private isLike = 0
  private likeCounts =0
  private loading = false
  created () {
    this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios
      .post(this.$apis.insectMonitor.addVideoPlayCounts, {
        videoId: this.videoId
      })
      .then((res) => {
        console.log(res)
        if (res) {
          this.isLike = res.isLike
          this.likeCounts = res.likeCounts
          this.detailInfo = res
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 关闭弹框
  handleClose () {
    this.$emit('closeDialog')
  }

  // 点赞
  onLike () {
    this.$axios.post(this.$apis.insectMonitor.addVideoLikeCounts, {
      videoId: this.videoId
    })
      .then((res) => {
        if (res && this.detailInfo) {
          this.isLike = res.isLike
          this.likeCounts = res.likeCounts

          this.isLike === 1 ? this.$message.success('点赞成功') : this.$message.success('取消点赞')
        }
      })
  }
}
