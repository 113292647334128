
import { Component, Vue, Prop } from 'vue-property-decorator'
import WorkInstructionDetail from './WorkInstructionDetail.vue'
import { WorkInstructionItem } from '@/types/insectMonitor'

@Component({
  components: { WorkInstructionDetail }
})
export default class WorkInstruction extends Vue {
  @Prop({ default: '' }) private insectName!: string
  private detailDialog = false
  private loading = false
  private dataArr: WorkInstructionItem[] = []
  private page = 1
  private size = 20
  private total = 0
  private getMore = false
  private workGuideId = ''
  created () {
    this.getData()
  }

  // 销毁
  destroyed () {
    window.removeEventListener('scroll', this.listScroll)
  }

  listScroll (e: any) {
    const scrollHeight = e.target.scrollHeight
    const scrollTop = e.target.scrollTop
    const clientHeight = e.target.clientHeight
    const distance = scrollHeight - scrollTop - clientHeight
    if (distance < 80 && this.getMore) {
      this.page++
      this.getMore = false
      this.getData()
    }
  }

  getData () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: WorkInstructionItem[] }>(
        this.$apis.insectMonitor.selectWorkGuideByInsect,
        {
          page: this.page,
          size: this.size,
          insectName: this.insectName
        }
      )
      .then((res) => {
        if (res) {
          this.total = res.total || 0

          this.dataArr = this.page === 1 ? res.list || [] : this.dataArr.concat(res.list)
          this.getMore = this.dataArr.length < res.total
        }
      })
      .finally(() => {
        this.loading = false
      })

    this.$nextTick(() => {
      ;(this.$refs.classWorkWrap as any).addEventListener(
        'scroll',
        this.listScroll
      )
    })
  }

  closeDialog () {
    this.detailDialog = false
  }

  onDetail (workGuideId: string) {
    this.workGuideId = workGuideId
    this.detailDialog = true
  }
}
