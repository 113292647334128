
import { Component, Vue, Prop } from 'vue-property-decorator'
import MedicamentDetail from './MediamentDetail.vue'
import { MedicamentItem } from '@/types/insectMonitor'

@Component({
  components: { MedicamentDetail }
})
export default class Medicament extends Vue {
  @Prop({ default: '' }) private insectName!: string
  private detailDialog = false
  private medicineId = ''
  private loading = false
  private dataArr: MedicamentItem[] = []
  private page = 1
  private size = 20
  private total = 0
  private getMore = false
  private watermark = '?imageView2/1/w/290/h/290/q/75|watermark/1/image/aHR0cDovL3FuLnBpYy5naW50ZWxsLmNuL2ppdXpoaV9sb2dvLnBuZw==/dissolve/100/gravity/SouthEast/dx/10/dy/10'
  created () {
    this.getData()
  }

  // 销毁
  destroyed () {
    window.removeEventListener('scroll', this.listScroll)
  }

  listScroll (e: any) {
    const scrollHeight = e.target.scrollHeight
    const scrollTop = e.target.scrollTop
    const clientHeight = e.target.clientHeight
    const distance = scrollHeight - scrollTop - clientHeight
    if (distance < 80 && this.getMore) {
      this.page++
      this.getMore = false
      this.getData()
    }
  }

  // 药剂列表
  getData () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: MedicamentItem[] }>(
        this.$apis.insectMonitor.selectMedicineByInsect,
        {
          page: this.page,
          size: this.size,
          insectName: this.insectName
        }
      )
      .then((res) => {
        console.log(res)
        if (res) {
          this.total = res.total || 0

          this.dataArr = this.page === 1 ? res.list || [] : this.dataArr.concat(res.list)
          this.getMore = this.dataArr.length < res.total
        }
      })
      .finally(() => {
        this.loading = false
      })

    this.$nextTick(() => {
      ;(this.$refs.listWrap as any).addEventListener(
        'scroll',
        this.listScroll
      )
    })
  }

  onDetail (medicineId: string) {
    this.medicineId = medicineId
    this.detailDialog = true
  }

  closeDialog () {
    this.detailDialog = false
  }
}
