
import { Component, Vue } from 'vue-property-decorator'
import Medicament from './Medicament.vue'
import WorkInstruction from './WorkInstruction.vue'
import ClassVideo from './ClassVideo.vue'
import { InsectDetail } from '@/types/insectMonitor'
@Component({
  components: {
    Medicament,
    WorkInstruction,
    ClassVideo
  }
})
export default class Recommend extends Vue {
  private activeNames = ['1', '2', '3', '4', '5']
  private carouselIndex = 0
  private activeTab = '1'

  private insectRecommend: InsectDetail | null = null
  private insectLoading = false
  private insectName = ''
  private watermark = '?imageView2/1/w/141/h/141/q/75|watermark/1/image/aHR0cDovL3FuLnBpYy5naW50ZWxsLmNuL2ppdXpoaV9sb2dvLnBuZw==/dissolve/100/gravity/SouthEast/dx/10/dy/10'

  created () {
    this.insectName = this.$route.params.insectName

    this.getinsectDetail()
  }

  getinsectDetail () {
    this.insectLoading = true
    this.$axios
      .get(
        this.$apis.insectMonitor.selectPestsRecommendedByInsect,
        {
          insectName: this.insectName
        }
      ).then(res => {
        if (res) {
          this.insectRecommend = res || null
        }
      }).finally(() => {
        this.insectLoading = false
      })
  }

  // 轮播改变
  carouselChange (index: number) {
    this.carouselIndex = index
  }

  // tab
  changeTab (val: any) {
    this.activeTab = val.name
  }
}
